/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const { ALL_LANGUAGES_AVAILABLE } = require("./src/constants")

// You can delete this file if you're not using it
require("./src/index.css")

